@use "libs/styles/src/lib/variables";

.commit-box {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  gap: variables.$space-m;
  align-items: center;
  padding: variables.$space-m;
  color: variables.$color-white;
  background: rgba(0, 0, 0, 50%);

  &--hidden {
    display: none;
  }
}
