@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.cookie-modal {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  max-width: 36rem;
  border-right: none;
  border-bottom: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: variables.$color-white;

  h3 {
    @include variables.header(2);
  }

  & > div {
    @include rfs.rfs(16px,gap);
    display: flex;
    flex-direction: column;
  }

  & > button {
    margin-left: auto;
  }

  @media (max-width: variables.$device-md) {
    border-right: unset;
    border-top-left-radius: 0;
  }
}
