@use "sass:color";
@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.button {
  @include rfs.padding(0 32px);
  @include variables.paragraph($big: true);
  display: inline-flex;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  border: 2px solid variables.$color-base;
  border-radius: variables.$border-radius-xxl;
  font-weight: 500;
  white-space: nowrap;
  color: variables.$color-dark-light;
  background: variables.$color-white;
  box-shadow: variables.$box-shadow-medium;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    border-color: color.adjust(variables.$color-purple, $lightness: 25%);
    transform: translateY(-6px);
  }

  &:disabled {
    border: none;
    color: variables.$color-white;
    background-color: variables.$color-disabled;
    cursor: not-allowed;
  }

  @media (max-width: variables.$device-md) {
    height: 38px;
  }
}

$colors: (
  "fund": variables.$color-fund,
  "org": variables.$color-org,
);
$d: color.adjust(variables.$color-white, $lightness: -10%);

@each $name, $color in $colors {
  .variation--#{$name} {
    border-color: $color;
    color: $color;

    &.filled {
      color: variables.$color-white;
      background: $color;
    }

    &:hover {
      $d: rgba(variables.$color-fund, 0.2);
      background: $d;
      box-shadow: variables.$box-shadow-medium-blue;
    }

    &:disabled {
      background-color: rgba($color, 0.5);
    }
  }
}

.variation--org {
  &.filled {
    &:hover {
      $d: color.adjust(variables.$color-org, $saturation: 30%, $lightness: 10%);
      border-color: variables.$color-org;
      color: variables.$color-white;
      background: $d;
    }
  }

  &.gradient {
    background: linear-gradient(0deg, rgba(255, 255, 255, 20%), rgba(255, 255, 255, 20%)),
      linear-gradient(92.19deg, #ea6b0e -157.92%, #53acde -71.21%, #6356db 70.71%, #d94002 172.93%);
    box-shadow: variables.$box-shadow-medium-purple;

    &:hover {
      transform: translateY(-8px);
      filter: hue-rotate(22deg);
    }
  }
}

.variation--fund {
  &.filled {
    &:hover {
      $d: color.adjust(variables.$color-fund, $saturation: 30%, $lightness: 10%);
      color: variables.$color-white;
      background: $d;
      box-shadow: variables.$box-shadow-medium-orange;
    }
  }

  &.gradient {
    background: linear-gradient(0deg, rgba(255, 255, 255, 20%), rgba(255, 255, 255, 20%)),
      linear-gradient(92.19deg, variables.$color-fund 10%, variables.$color-fund 50%, #f1392b 90%);
    box-shadow: variables.$box-shadow-light-orange;

    &:hover {
      box-shadow: variables.$box-shadow-medium-orange;
      filter: brightness(105%);
    }
  }

  &:hover {
    border-color: variables.$color-fund;
  }
}

.gradient {
  border: none;
  font-weight: bold;
  color: white;
  transition: variables.$transition-m;
}

.shadowed {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 3%);
}

.minimal {
  border: none;
  padding: 0;

  &:hover {
    border: none;
    background: none;
  }
}

.size {
  &--small {
    height: 32px;
    padding: 0 variables.$space-m;
  }

  &--medium {
    height: 48px;
    padding: 0 variables.$space-l;
    font-size: variables.$font-size-m;
  }

  &--large {
    height: 120px;
    padding: 0 variables.$space-xl;
  }
}

.font-size {
  &--small {
    @include variables.paragraph;
    font-weight: 500;
  }

  &--medium {
    @include variables.header(4);
    font-weight: 500;
  }

  &--large {
    @include variables.header(2);
  }
}

.icon {
  @include rfs.padding-left(24px);
  @include rfs.padding-right(16px);

  svg {
    width: auto;
    height: 17px;
    color: rgba(variables.$color-purple, 0.75);
  }
}

.card--icon {
  @include rfs.rfs(20px, gap);
  @include rfs.padding-top(20px);
  @include rfs.padding-bottom(20px);
  display: inline-flex;
  width: 374px;
  border: 1px solid #e4ebf4;
  border-radius: 28px;
  padding-left: 28px;
  white-space: unset;
  background: linear-gradient(180deg, #fff 0%, #fefeff 75.3%, #f9fdff 100%);
  background-color: #fff;
  box-shadow: 0 18px 43px rgba(75, 51, 142, 6%);
  cursor: pointer;

  & > *:last-child {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
    text-align: left;
    color: variables.$color-disabled;

    & > *:first-child {
      transition: variables.$transition-s;
    }
  }

  &:hover {
    box-shadow: variables.$box-shadow-medium-purple;

    .icon-div {
      --c: #{color.adjust(variables.$color-purple, $lightness: 25%)};
      border-color: var(--c);
      box-shadow: variables.$box-shadow-medium-purple;
      transform: translateY(-6px);

      & > svg {
        animation: variables.$spin-animation;
      }
    }

    & > *:last-child > *:first-child {
      color: variables.$color-purple;
    }
  }

  @media (max-width: variables.$device-md) {
    width: unset;
  }
}

.icon-div {
  flex: 0 0 76px;
  height: 76px;
  border: 1.5px solid color.adjust(variables.$color-lighter, $lightness: 2.5%);
  border-radius: 100px;
  padding: 16px;
  transition: all 0.2s ease;

  & > svg {
    width: 100%;
    height: 100%;
  }
}
