@use "libs/styles/src/lib/variables";
@use "libs/styles/src/lib/globals";

.app {
  display: flex;
  flex-direction: column;
  margin-top: variables.$NAVBAR_HEIGHT;

  @media (max-width: variables.$device-md) {
    margin-top: 4rem;
  }
}
