@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.footer-wrapper {
  @include variables.max-width;
  @include rfs.padding-bottom(48px);
  color: variables.$color-slate;

  hr {
    @include rfs.margin-top(3.5rem);
    @include rfs.margin-bottom(2rem);
    width: 100%;
    height: 1px;
    border: none;
    background-color: variables.$color-lighter;
  }
}

.links-container {
  @include rfs.rfs(3vw, gap);
  display: flex;
  justify-content: space-evenly;

  @media (max-width: variables.$device-sm) {
    flex-direction: column;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h6 {
      @include variables.paragraph;
      @include rfs.margin-top(12px);
      @include rfs.margin-bottom(4px);
      color: variables.$color-slate;
    }

    a {
      @include variables.label;
      color: variables.$color-disabled;
    }
  }
}

.links-logo {
  flex: 1;
  gap: 16px;

  span {
    @include variables.paragraph($big: true);
  }
}

.extra-container {
  display: flex;
  align-items: center;

  @media (max-width: variables.$device-md) {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 100%;
  }
}

.ndao-ein {
  @include rfs.margin-bottom(12px);
  max-width: 260px;
  margin-top: 0;

  @include variables.label;
}

.ndao-copyright {
  @include variables.label;
  opacity: 0.5;
}

.extra-app-button {
  margin-left: auto;

  @media (max-width: variables.$device-md) {
    margin: unset;
  }
}
