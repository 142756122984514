@use "./variables";
@use "~rfs/scss" as rfs;
@use "./fonts";

:root {
  --font-size-xs: #{variables.$font-size-xs};
  --font-size-s: #{variables.$font-size-s};
  --font-size-ms: #{variables.$font-size-ms};
  --font-size-m: #{variables.$font-size-m};
  --font-size-ml: #{variables.$font-size-ml};
  --font-size-l: #{variables.$font-size-l};
  --font-size-xl: #{variables.$font-size-xl};
  --font-size-xxl: #{variables.$font-size-xxl};
  --font-size-xxxl: #{variables.$font-size-xxxl};
  --font-size-xxxxl: #{variables.$font-size-xxxxl};
  --font-primary: "Graphik", sans-serif;
  --font-secondary: "Signifier", serif;
  --selected-segment-width: auto;
  --selected-segment-x-pos: 0;
  --scrollbar-width: 16px; /* Standard width but we might want to calculate at load. */
  --full-width: calc(100vw - var(--scrollbar-width));
  --color-dark-light: #{variables.$color-dark-light};
  --color-logo-blue: #{variables.$color-logo-blue};
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  @include rfs.font-size(1rem);
  position: relative;
  width: 100%;
  font-family: variables.$font-primary;
  /* stylelint-disable-next-line property-no-unknown */
  font-smooth: subpixel-antialiased;

  /* Allow percentage-based heights in the application */
  block-size: 100%;

  /* Making sure text size is only controlled by font-size */
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizespeed;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  html:focus-within {
    scroll-behavior: smooth;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include variables.title;
}

h1 {
  @include rfs.margin-top(120px);
  font-size: 4rem;
  color: variables.$color-logo-blue;
}

h2 {
  @include variables.title(2);
}

h3 {
  @include variables.title(3);
}

h4 {
  @include variables.header(3);
}

h5 {
  @include variables.header(4);
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

p,
text {
  @include variables.paragraph;
}

fieldset {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  padding: 0;
}

#__next {
  top: 0;
  left: 0;
  width: 100%;
}

hr {
  border-color: variables.$color-light-border;
  border-width: 0.5px;
}

// @TODO: Mobile implentation of mixins
@media (max-width: variables.$device-md) {
  :root {
    --full-width: calc(100% + 2rem);
  }
}

@media (min-width: variables.$device-md) {
  html,
  body {
    font-size: 15px;
  }

  h1 {
    font-size: variables.$font-size-xxxl;
  }
}

@media (min-width: variables.$device-lg) {
  html,
  body {
    font-size: 16px;
  }
}

$directions: (row, column);

@each $direction in $directions {
  .flex-#{$direction} {
    display: flex;
    flex-direction: $direction;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}

.center {
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}
