@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: variables.$NAVBAR_HEIGHT;
  background-color: rgba(variables.$color-white, 70%);
  backdrop-filter: saturate(1.8) blur(20px);

  @media (max-width: variables.$device-md) {
    height: fit-content;
  }

  &__inner {
    @include variables.max-width;
    @include rfs.padding-left(116px);
    @include rfs.padding-right(116px);
    @include rfs.padding-top(36px);
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    @media (max-width: variables.$device-md) {
      @include rfs.padding(12px 30px);

      & > *:last-child {
        button {
          @include rfs.padding-right(24px);
          @include rfs.margin-right(-10px);
        }

        svg {
          display: none;
        }
      }
    }

    & > div,
    & > nav {
      flex-basis: 33%;

      @media (max-width: variables.$device-md) {
        &:first-child {
          height: 20px;
        }
      }
    }

    *:not(.logo) a:not(.app-button) {
      @include rfs.padding(12px 10px);
      @include variables.label;
      line-height: revert;
      white-space: nowrap;
      text-decoration: none;
      color: variables.$color-gray;
      transition: variables.$transition-s;

      &:hover {
        color: variables.$color-purple;
      }
    }
  }
}

.navigation {
  @include rfs.rfs(12px, gap);
  justify-content: flex-start;
}

.learn-container {
  position: relative;
  display: flex;

  svg {
    margin-left: 1px;
    transition: variables.$transition-m;

    path {
      stroke: variables.$color-disabled;
    }
  }

  & > div {
    @include rfs.margin(6px -2px);
    @include rfs.padding(4px 12px);
    @include rfs.padding-bottom(6px);
    position: absolute;
    top: 32px;
    z-index: 1;
    display: none;
    flex-direction: column;
    border: 1px solid variables.$color-lighter;
    border-radius: variables.$border-radius-s;
    white-space: nowrap;
    background-color: rgba(variables.$color-white, 70%);
    box-shadow: variables.$box-shadow-medium;
    backdrop-filter: saturate(1.8) blur(20px);

    & > a {
      @include rfs.padding(12px 8px);
    }

    & > *:not(:last-child) {
      border-bottom: 1px solid variables.$color-light-border;

      &:hover {
        border-color: rgba(variables.$color-purple, 0.5);
      }
    }
  }

  @media (hover: hover) {
    &:hover > div,
    & > div:hover {
      display: flex;
    }

    &:hover {
      & > a {
        color: variables.$color-purple;
      }

      svg {
        transform: scaleY(-1);

        path {
          stroke: variables.$color-purple;
        }
      }
    }
  }
}

.logo {
  flex: 1;
  text-align: center;

  @media (max-width: variables.$device-md) {
    height: 30px;
  }

  a {
    height: fit-content;
  }
}

.extra {
  @include rfs.rfs(16px, gap);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hamburger-menu {
  @include rfs.padding(40px 40px 40px 40px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: variables.$color-near-white;

  & > svg {
    align-self: flex-end;
  }

  & > nav {
    align-items: flex-start;

    a {
      @include variables.header(2);
      @include rfs.margin-bottom(8px);
      text-align: left;
      color: variables.$color-disabled;

      &.active {
        color: variables.$color-slate;
      }
    }

    .sub-link {
      @include variables.header(3);
      @include rfs.margin-top(-4px);
      @include rfs.margin-left(12px);
    }

    & > :last-child {
      @include rfs.margin-top(24px);
      width: fit-content;
    }
  }

  & > div {
    width: 100%;
    margin-top: auto;

    & > hr {
      @include rfs.margin-bottom(55px);
      @include rfs.margin-left(-10px);
      @include rfs.margin-right(-10px);
      height: 1px;
      border: none;
      background-color: rgba(variables.$color-slate, 0.42);
    }

    & > svg > path {
      fill: variables.$color-slate;
    }
  }
}

@media (min-width: variables.$device-md) {
  .show-mobile {
    display: none;
  }
}

@media (max-width: variables.$device-md) {
  .show-desktop {
    display: none;
  }
}
