@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.card {
  @include rfs.padding(24px);
  border: 1px solid variables.$color-lighter;
  border-radius: variables.$space-l;
  background-color: variables.$color-white;
  box-shadow: variables.$box-shadow-medium;

  &--clickable {
    cursor: pointer;
  }

  [class*="avatar"]:not([class*="avatar-container"]) {
    transition: variables.$transition-m;
  }

  &--rainbow {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 25px;
    padding: 2px;
    overflow: hidden;
    box-shadow: 0 9.7112px 31.0758px rgba(234, 107, 14, 10%);

    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background: linear-gradient(50.39deg, #53acde -22.24%, #6356db 32.33%, #d94002 91.96%, #ea6b0e 139.79%);
      content: "";
    }
  }
}

.content {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: variables.$space-l;
  background: variables.$color-white;
}
